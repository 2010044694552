<script setup>
import { ref, onMounted } from 'vue';
import { loadFontAwesome } from '@/Utils/dynamicImports';

defineProps({
    title: {
        type: String,
        required: true
    },
    icon: {
        type: String,
        required: true
    },
    description: {
        type: String,
        default: ''
    },
    variant: {
        type: String,
        default: 'default' // Can be 'default', 'feature', or 'stat'
    }
});

// Load Font Awesome icons
onMounted(async () => {
    await loadFontAwesome();
});
</script>

<template>
    <div class="group relative rounded-xl overflow-hidden transition-all duration-300"
         :class="[
             variant === 'feature' 
                 ? 'bg-[#24745C] shadow-lg hover:shadow-2xl' 
                 : variant === 'stat'
                     ? 'bg-white/80 backdrop-blur-sm hover:shadow-xl'
                     : 'bg-white shadow-lg hover:shadow-2xl hover:scale-[1.02]'
         ]">
        <div class="absolute inset-0 bg-gradient-to-r" 
             :class="[
                 variant === 'feature'
                     ? 'from-white/10 via-transparent to-transparent group-hover:from-white/20'
                     : variant === 'stat'
                         ? 'from-secondary/5 via-transparent to-transparent group-hover:from-secondary/10'
                         : 'from-primary/5 via-transparent to-transparent group-hover:from-primary/10'
             ]"></div>
        <div class="relative p-6">
            <div class="flex flex-col items-center sm:items-start sm:flex-row sm:gap-4">
                <div class="flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center mb-4 sm:mb-0 group/icon transition-all duration-300"
                     :class="[
                         variant === 'feature'
                             ? 'bg-white/20 group-hover:bg-white/30'
                             : variant === 'stat'
                                 ? 'bg-secondary/10 group-hover:bg-secondary/20'
                                 : 'bg-primary/10 group-hover:bg-primary/20'
                     ]">
                    <i :class="[
                        'fa-solid',
                        icon,
                        'transform transition-all duration-300 group-hover/icon:rotate-12 group-hover/icon:scale-110',
                        variant === 'feature'
                            ? 'text-white group-hover/icon:text-white'
                            : variant === 'stat'
                                ? 'text-secondary text-xl group-hover/icon:text-secondary'
                                : 'text-primary/70 group-hover/icon:text-primary'
                    ]"></i>
                </div>
                <div>
                    <h3 :class="[
                        'transition-colors duration-300',
                        variant === 'stat'
                            ? 'text-xl sm:text-2xl lg:text-3xl font-bold text-gray-900 mb-1'
                            : 'text-lg font-semibold mb-2 text-center sm:text-left',
                        variant === 'feature'
                            ? 'text-white group-hover:text-white'
                            : 'text-gray-900 group-hover:text-primary'
                    ]">
                        {{ title }}
                    </h3>
                    <p v-if="description" 
                       :class="[
                           'leading-relaxed',
                           variant === 'stat'
                               ? 'text-sm text-gray-500'
                               : 'text-sm',
                           variant === 'feature'
                               ? 'text-white/90 group-hover:text-white'
                               : 'text-gray-600 group-hover:text-gray-700'
                       ]">
                        {{ description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
